body {
  margin: 0;
  background: var(--main-bg);
  transition: background 0.35s;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f6f8;
  overflow-x: hidden;
}

/* width */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #555;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.link {
  font-weight: 600;
  cursor: pointer;
  font-size: 13px;
  color: #008fd8;
  text-decoration: none;
}

.without-padding input {
  padding: 4px;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

html {
  scroll-behavior: smooth;
}